import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
const rolesApi = factory.get("roles");
const initialState = {
  searchBody: {},
  mainDialog: false,
  selectedUserId: null,
  permissionsForm: {
    user_id: null,
    permissions: [],
  },
  roles: {
    loading: false,
    data: [],
    total: 0,
  },
  permissions: {
    loading: false,
    data: {},
  },
  userPermissions: {
    loading: false,
    data: [],
    total: 0,
  },
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setUserPermissionsDialog: (state, { payload }) => {
      state.mainDialog = !state.mainDialog;
      if (payload) {
        state.selectedUserId = payload.id;
      } else {
        state.selectedUserId = null;
      }
    },
    setRolesDataTable: (state, { payload }) => {
      state.roles.data = payload.data.data;
      state.roles.total = payload.data.total;
    },
    setPermissionsDataTable: (state, { payload }) => {
      state.permissions.data = payload.data.data;
    },
    setUserPermissionsDataTable: (state, { payload }) => {
      state.userPermissions.data = payload.data.data;
      state.userPermissions.total = payload.data.total;
    },
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    resetForm: (state, action) => {
      state.permissionsForm = initialState.permissionsForm;
    },
  },
});

export const {
  setLoading,
  setRolesDataTable,
  setPermissionsDataTable,
  setUserPermissionsDataTable,
  setUserPermissionsDialog,
  resetForm,
} = rolesSlice.actions;

export default rolesSlice.reducer;

//axios
const getAllRoles = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("roles"));
    const res = await rolesApi.getAll(params);
    dispatch(setRolesDataTable(res));
    dispatch(setLoading("roles"));
  } catch (err) {
    dispatch(setLoading("roles"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getAllPermissions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("permissions"));
    const res = await rolesApi.getAllUserPermissions(params);
    dispatch(setPermissionsDataTable(res));
    dispatch(setLoading("permissions"));
  } catch (err) {
    dispatch(setLoading("permissions"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getUserPermissions = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("permissions"));
    const res = await rolesApi.getUserPermissions(params);
    dispatch(setUserPermissionsDataTable(res));
    dispatch(setLoading("permissions"));
  } catch (err) {
    dispatch(setLoading("permissions"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const setUserPermission = (data, _SearchBody) => async (dispatch) => {
  try {
    dispatch(setLoading("permissions"));
    await rolesApi.setUserPermission(data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    dispatch(setLoading("permissions"));
    dispatch(setUserPermissionsDialog());
    // dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(setLoading("permissions"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const setUserRole = (data, _SearchBody) => async (dispatch) => {
  try {
    await rolesApi.setUserRole(data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    // dispatch(setDialog());
    // dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
export const Roles = {
  getAllRoles,
  getAllPermissions,
  setUserPermission,
  setUserRole,
  getUserPermissions,
};
