import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "../notificationSlice";
import { factory } from "../../../api/apiFactory";
const jOrderApi = factory.get("jOrder");
const initialState = {
  searchBody: {
    number: "",
    j_sub_category_id: null,
    j_item_id: null,
    start_date: null,
    end_date: null,
    driver_id: null,
    j_order_status_id: null,
    pos_id: null,
    province_id: null,
    has_debt: null,
    pos_number: "",
  },
  jOrder: {
    loading: false,
    dialog: false,
    active: false,
    form: {
      id: null,
      pos_user_id: null,
      note: "",
      admin_discount: 0,
      j_items: [
        {
          j_item_id: null,
          count: "",
          last_buying_price: 0,
          expiry_date: null,
          expiry_date_list: [],
        },
      ],
    },
    details: {},
  },
  jOrders: {
    loading: false,
    data: [],
    total: 0,
    total_amount: 0,
  },
  jOrderStatuses: {
    loading: false,
    data: [],
    total: 0,
  },
  adminDiscount: {
    loading: false,
    dialog: false,
    form: {},
  },
  orderItemDiscount: {
    loading: false,
    dialog: false,
    form: {},
  },
  addItemToOrder: {
    loading: false,
    dialog: false,
    form: {
      j_order_id: null,
      j_item_id: null,
      count: 0,
    },
  },
  assignDriver: {
    loading: false,
    dialog: false,
    form: {
      driver_id: null,
      order_id: null,
    },
  },
  orderStatusHistory: {
    dialog: false,
    data: [],
  },
  orderPrint: {
    dialog: false,
    data: [],
  },
  changePos: {
    loading: false,
    dialog: false,
    form: {
      order_id: null,
      user_id: null,
      note: "",
    },
  },
  changeAdminNote: {
    loading: false,
    dialog: false,
    form: {
      order_id: null,
      admin_note: "",
    },
  },
  orderItemChangeHistory: {
    loading: false,
    dialog: false,
    data: [],
  },
  orderChangeHistory: {
    loading: false,
    dialog: false,
    data: [],
  },
};

export const jOrderSlice = createSlice({
  name: "jOrder",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.jOrder.dialog = !state.jOrder.dialog;
    },
    setAdminDiscountDialog: (state, { payload }) => {
      state.adminDiscount.dialog = !state.adminDiscount.dialog;
      state.adminDiscount.form = payload ?? {};
    },
    setAddItemToOrderDialog: (state, { payload }) => {
      state.addItemToOrder.dialog = !state.addItemToOrder.dialog;
      if (payload) {
        state.addItemToOrder.form.j_order_id = payload;
      }
    },
    setOrderStatusHistoryDialog: (state, { payload }) => {
      state.orderStatusHistory.dialog = !state.orderStatusHistory.dialog;
      if (payload) {
        state.orderStatusHistory.data = payload;
      } else {
        state.orderStatusHistory.data = [];
      }
    },
    setOrderPrintDialog: (state, { payload }) => {
      state.orderPrint.dialog = !state.orderPrint.dialog;
      if (payload) {
        state.orderPrint.data = payload;
      } else {
        state.orderPrint.data = [];
      }
    },
    setAdminDiscountLoading: (state, action) => {
      state.adminDiscount.loading = !state.adminDiscount.loading;
    },
    setOrderItemDiscountDialog: (state, { payload }) => {
      state.orderItemDiscount.dialog = !state.orderItemDiscount.dialog;
      state.orderItemDiscount.form = payload ?? {};
    },
    setOrderItemDiscountLoading: (state, action) => {
      state.orderItemDiscount.loading = !state.orderItemDiscount.loading;
    },
    setDataTable: (state, { payload }) => {
      state.jOrders.data = payload.data.data;
      state.jOrders.total = payload.data.total;
      state.jOrders.total_amount = payload.data.total_amount;
    },
    setStatusList: (state, { payload }) => {
      state.jOrderStatuses.data = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.jOrder.form = payload;
    },
    setOrderDetails: (state, { payload }) => {
      state.jOrder.details = payload;
    },
    setLoading: (state, action) => {
      state.jOrders.loading = !state.jOrders.loading;
    },
    resetForm: (state, action) => {
      state.jOrder.form = initialState.jOrder.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
    setAssignDriverLoading: (state, action) => {
      state.assignDriver.loading = !state.assignDriver.loading;
    },
    setAssignDriverDialog: (state, { payload }) => {
      state.assignDriver.dialog = !state.assignDriver.dialog;
      if (payload) {
        state.assignDriver.form = payload;
      } else {
        state.assignDriver.form = {
          driver_id: null,
          order_id: null,
        };
      }
    },
    setChangePosLoading: (state, action) => {
      state.changePos.loading = !state.changePos.loading;
    },
    setChangePosDialog: (state, { payload }) => {
      state.changePos.dialog = !state.changePos.dialog;
      if (payload) {
        state.changePos.form = payload;
      } else {
        state.changePos.form = {
          driver_id: null,
          order_id: null,
        };
      }
    },
    setChangeAdminNoteLoading: (state, action) => {
      state.changeAdminNote.loading = !state.changeAdminNote.loading;
    },
    setChangeAdminNoteDialog: (state, { payload }) => {
      state.changeAdminNote.dialog = !state.changeAdminNote.dialog;
      if (payload) {
        state.changeAdminNote.form = payload;
      } else {
        state.changeAdminNote.form = {
          order_id: null,
          admin_note: "",
        };
      }
    },
    setOrderItemChangeHistoryDialog: (state, { payload }) => {
      state.orderItemChangeHistory.dialog =
        !state.orderItemChangeHistory.dialog;
      if (payload) {
        state.orderItemChangeHistory.data = payload;
      } else {
        state.orderItemChangeHistory.data = [];
      }
    },
    setOrderChangeHistoryDialog: (state, { payload }) => {
      state.orderChangeHistory.dialog = !state.orderChangeHistory.dialog;
      if (payload) {
        state.orderChangeHistory.data = payload;
      } else {
        state.orderChangeHistory.data = [];
      }
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setOrderDetails,
  setDataTable,
  resetForm,
  setSearchBody,
  setStatusList,
  setAdminDiscountDialog,
  setAdminDiscountLoading,
  setOrderItemDiscountDialog,
  setOrderItemDiscountLoading,
  setAddItemToOrderDialog,
  setAssignDriverDialog,
  setAssignDriverLoading,
  setOrderStatusHistoryDialog,
  setOrderPrintDialog,
  setChangePosDialog,
  setChangePosLoading,
  setChangeAdminNoteDialog,
  setChangeAdminNoteLoading,
  setOrderChangeHistoryDialog,
  setOrderItemChangeHistoryDialog,
} = jOrderSlice.actions;

export default jOrderSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jOrder"));
    const res = await jOrderApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("jOrder"));
  } catch (err) {
    dispatch(setLoading("jOrder"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const updateStatus =
  (order_id, params, _SearchBody, type) => async (dispatch) => {
    try {
      dispatch(setLoading("jOrder"));
      await jOrderApi.setStatus(order_id, params);
      if (type === "getAll") {
        dispatch(getAll(_SearchBody));
      } else if (type === "getById") {
        dispatch(getOrderDetails(order_id));
      }
      dispatch(setLoading("jOrder"));
    } catch (err) {
      dispatch(setLoading("jOrder"));
      dispatch(
        showNotification({
          message: err.response.data.errors[0],
          type: "error",
        })
      );
      throw new Error(err);
    }
  };

const getStatuses = (params) => async (dispatch) => {
  try {
    const res = await jOrderApi.getStatus(params);
    dispatch(setStatusList(res));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await jOrderApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("jOrder"));
    const res = await jOrderApi.getById(id);
    dispatch(setById(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("jOrder"));
  } catch (err) {
    dispatch(setLoading("jOrder"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getByIdForPrint = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("jOrder"));
    const res = await jOrderApi.getById(id);
    dispatch(setOrderPrintDialog(res.data.data[0]));
    dispatch(setLoading("jOrder"));
  } catch (err) {
    dispatch(setLoading("jOrder"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("jOrder"));
    const res = await jOrderApi.getById(id);
    dispatch(setOrderDetails(res.data.data[0]));
    dispatch(setLoading("jOrder"));
  } catch (err) {
    dispatch(setLoading("jOrder"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const create = (data, _SearchBody) => async (dispatch) => {
  try {
    const res = await jOrderApi.create(data);
    dispatch(
      showNotification({
        message: "تم انشاء الطلب بنجاح",
        type: "success",
      })
    );
    dispatch(setDialog());
    dispatch(getAll(_SearchBody));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const cancelOrderItem = (id, data, orderId) => async (dispatch) => {
  try {
    const res = await jOrderApi.cancelOrderItem(id, data);
    dispatch(
      showNotification({
        message: "تم الغاء المنتج من الطلب بنجاح",
        type: "success",
      })
    );
    dispatch(getOrderDetails(orderId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const updateOrderItemCount = (id, data, orderId) => async (dispatch) => {
  try {
    const res = await jOrderApi.updateItemCount(id, data);
    dispatch(
      showNotification({
        message: "تم تعديل كمية المنتج بنجاح",
        type: "success",
      })
    );
    dispatch(getOrderDetails(orderId));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const editAdminDiscount = (id, data, type) => async (dispatch) => {
  try {
    dispatch(setAdminDiscountLoading());
    const res = await jOrderApi.editAdminDiscount(id, data);
    dispatch(setAdminDiscountLoading());
    dispatch(
      showNotification({
        message: "تم تعديل الخصم",
        type: "success",
      })
    );
    if (type === "orderDetails") {
      dispatch(getOrderDetails(id));
    }
    dispatch(setAdminDiscountDialog({}));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setAdminDiscountLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const editOrderItemDiscount = (id, data, orderId, type) => async (dispatch) => {
  try {
    dispatch(setOrderItemDiscountLoading());
    const res = await jOrderApi.editOrderItemDiscount(id, data);
    dispatch(setOrderItemDiscountLoading());
    dispatch(
      showNotification({
        message: "تم تعديل الخصم",
        type: "success",
      })
    );
    if (type === "orderDetails") {
      dispatch(getOrderDetails(orderId));
    }
    dispatch(setOrderItemDiscountDialog({}));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setOrderItemDiscountLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const addItemToOrder = (data, type) => async (dispatch) => {
  try {
    dispatch(setOrderItemDiscountLoading());
    const res = await jOrderApi.addItemToOrder(data);
    dispatch(setOrderItemDiscountLoading());
    dispatch(
      showNotification({
        message: "تم تعديل الخصم",
        type: "success",
      })
    );
    if (type === "orderDetails") {
      dispatch(getOrderDetails(data.j_order_id));
    }
    dispatch(setAddItemToOrderDialog());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setOrderItemDiscountLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const assignDriver = (id, data, searchBody, type) => async (dispatch) => {
  try {
    dispatch(setAssignDriverLoading());
    const res = await jOrderApi.assignDriver(id, data);
    dispatch(setAssignDriverLoading());
    dispatch(
      showNotification({
        message: "تم تعديل الخصم",
        type: "success",
      })
    );
    if (type) {
      dispatch(getOrderDetails(id));
    } else {
      dispatch(getAll(searchBody));
    }
    dispatch(setAssignDriverDialog());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setAssignDriverLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const changePos = (id, data, searchBody, type) => async (dispatch) => {
  try {
    dispatch(setChangePosLoading());
    const res = await jOrderApi.changePos(id, data);
    dispatch(setChangePosLoading());
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    if (type) {
      dispatch(getOrderDetails(id));
    } else {
      dispatch(getAll(searchBody));
    }
    dispatch(setChangePosDialog());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setChangePosLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const changeAdminNote = (id, data, searchBody, type) => async (dispatch) => {
  try {
    dispatch(setChangeAdminNoteLoading());
    const res = await jOrderApi.changeAdminNote(id, data);
    dispatch(setChangeAdminNoteLoading());
    dispatch(
      showNotification({
        message: "تم التعديل بنجاح",
        type: "success",
      })
    );
    if (type) {
      dispatch(getOrderDetails(id));
    } else {
      dispatch(getAll(searchBody));
    }
    dispatch(setChangeAdminNoteDialog());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setChangeAdminNoteLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const exportExcel = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("jOrder"));
    const res = await jOrderApi.exportExcel({
      ...params,
      is_excel: 1,
    });
    let url = window.URL.createObjectURL(res.data);
    let a = document.createElement("a");
    a.href = url;
    a.download = `pos.xlsx`;
    a.click();
    dispatch(setLoading("jOrder"));
  } catch (err) {
    dispatch(setLoading("jOrder"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

const recalculateJOrder = (id, data, searchBody, type) => async (dispatch) => {
  try {
    dispatch(setChangeAdminNoteLoading());
    const res = await jOrderApi.recalculate(id, data);
    dispatch(setChangeAdminNoteLoading());
    dispatch(
      showNotification({
        message: "تمت عملية اعادة الاحتساب بنجاح",
        type: "success",
      })
    );
    if (type) {
      dispatch(getOrderDetails(id));
    } else {
      dispatch(getAll(searchBody));
    }
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setChangeAdminNoteLoading());
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const JOrder = {
  getAll,
  deleteById,
  getById,
  getStatuses,
  updateStatus,
  create,
  updateOrderItemCount,
  cancelOrderItem,
  getOrderDetails,
  editAdminDiscount,
  editOrderItemDiscount,
  addItemToOrder,
  assignDriver,
  getByIdForPrint,
  changePos,
  changeAdminNote,
  exportExcel,
  recalculateJOrder,
};
