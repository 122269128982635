import api from "../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/role?${params}`);
  },
  getAllUserPermissions() {
    return api.get(`/api/v1/permissions`);
  },
  getUserPermissions(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/user-permissions?${params}`);
  },
  setUserPermission(data) {
    return api.put(`/api/v1/set-permissions-user`, data);
  },
  setUserRole(data) {
    return api.post(`/api/v1/set-role`, data);
  },
};
