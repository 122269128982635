import {
  Grid,
  LinearProgress,
  Card,
  CardContent,
  Link,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Reports } from "../../../../../app/slices/reportsSlice";

const RepDetailsTabel = () => {
  const dispatch = useDispatch();

  const _RepDetails = useSelector((state) => state.reports.repDetails);
  const loading = useSelector((state) => state.reports.reports.loading);

  const [search, setSearch] = useState({
    is_client: false,
  });

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "رقم الهاتف",
      flex: 1,
    },
    {
      field: "cash",
      headerName: "الكاش (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            onClick={() => {
              localStorage.setItem("isWholeSale", "0");
            }}
            href={`/app/wallets/transactions/${params.row.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.row?.cash?.toLocaleString()}
          </Link>
        );
      },
    },
    {
      field: "balance",
      headerName: "الرصيد (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row?.balance?.toLocaleString()}</>;
      },
    },
    {
      field: "debt",
      headerName: "الديون (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row?.debt?.toLocaleString()}</>;
      },
    },
    {
      field: "total_target",
      headerName: "المبيعات هذا الشهر (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row?.total_target?.toLocaleString()}</>;
      },
    },
    {
      field: "rep_pos_count",
      headerName: "عدد نقاط البيع",
      flex: 1,
    },
    {
      field: "total_pos_balance",
      headerName: "رصيد النقاط (IQD)",
      flex: 1,
      renderCell: (params) => params.row?.total_pos_balance?.toLocaleString(),
    },
    {
      field: "total_pos_debt",
      headerName: "ديون النقاط (IQD)",
      flex: 1,
      renderCell: (params) => params.row?.total_pos_debt?.toLocaleString(),
    },
    // {
    //   field: "last_transaction",
    //   headerName: "رقم آخر معاملة",
    //   flex: 1,
    //   valueGetter: (params) => params.row.last_transaction ? params.row.last_transaction.number : "",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.last_transaction ? params.row.last_transaction.number : "---"}
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "created_at",
    //   headerName: "تاريخ آخر معاملة",
    //   flex: 1,
    //   valueGetter: (params) => params.row.last_transaction ? dayjs(params.row.last_transaction.created_at).format(" YYYY-MM-DD / MM:hha ") : "",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params.row.last_transaction ? dayjs(params.row.last_transaction.created_at).format(" YYYY-MM-DD / MM:hha ") : "---"}
    //       </>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        Reports.getAll({
          is_client: search.is_client,
        })
      );
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير تفاصيل المندوب | نظام دجلة",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={12} md={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={search.is_client === 1 ? true : false}
                  onChange={(e) =>
                    setSearch({
                      ...search,
                      is_client: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
              label="العملاء"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_RepDetails.data ? (
                <DataGrid
                  rows={_RepDetails.data}
                  columns={columns}
                  rowCount={_RepDetails.total}
                  loading={loading}
                  rowHeight={40}
                  selectionModel={[]}
                  rowsPerPageOptions={[1000]}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: customToolbar,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RepDetailsTabel;
