import api from "../../apiConfig";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== "" && data[key] !== undefined) {
          params += `${key}=${data[key]}&`;
        }
      });
    }
    return api.get(`/api/v1/j-order?${params}`);
  },
  getById(id) {
    return api.get(`/api/v1/j-order/${id}`);
  },
  getStatus() {
    return api.get(`/api/v1/j-order/status`);
  },
  setStatus(order_id, data) {
    return api.post(`/api/v1/j-order/status_by_admin/${order_id}`, data);
  },
  create(data) {
    return api.post(`/api/v1/j-order/admin`, data);
  },
  updateItemCount(id, data) {
    return api.put(`/api/v1/j-order-item/${id}`, data);
  },
  cancelOrderItem(id, data) {
    return api.post(`/api/v1/j-order-item/cancel/${id}`, data);
  },
  editAdminDiscount(id, data) {
    return api.put(`/api/v1/j-order/admin-discount/${id}`, data);
  },
  editOrderItemDiscount(id, data) {
    return api.put(`/api/v1/j-order/item-discount/${id}`, data);
  },
  addItemToOrder(data) {
    return api.post(`/api/v1/j-order-item/add-item`, data);
  },
  assignDriver(order_id, data) {
    return api.put(`/api/v1/j-order/assign-driver/${order_id}`, data);
  },
  changePos(order_id, data) {
    return api.put(`/api/v1/j-order/change-pos/${order_id}`, data);
  },
  changeAdminNote(order_id, data) {
    return api.put(`/api/v1/j-order/admin-note/${order_id}`, data);
  },
  exportExcel(data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return api.get(`/api/v1/j-order?${params}`, { responseType: "blob" });
  },
  recalculate(order_id) {
    return api.put(`/api/v1/j-order/recalculate/${order_id}`);
  },
};
